// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file-input {
  width: 100%;
}

.file-upload-box {
  height: 37.6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  padding-inline-start: 15px;
}

.file-upload-text {
  color: #888;
}

.upload-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

.file-upload-box:hover {
  border-color: #000;
}

.upload-icon-button:hover {
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/VoiceEditSubscriber/voiceEditSubscriber.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,+CAA+C;EAC/C,0BAA0B;AAC5B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".custom-file-input {\n  width: 100%;\n}\n\n.file-upload-box {\n  height: 37.6px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 0.5);\n  border: 1px solid rgba(0, 0, 0, 0.5) !important;\n  padding-inline-start: 15px;\n}\n\n.file-upload-text {\n  color: #888;\n}\n\n.upload-icon-button {\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-size: 18px;\n  color: #888;\n}\n\n.file-upload-box:hover {\n  border-color: #000;\n}\n\n.upload-icon-button:hover {\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
