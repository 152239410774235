import { Table } from "react-bootstrap";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getAllRestaurants,
  GetRestaurantOrdersDetails,
} from "../../utilies/api";
import { notifyError } from "../../Components/toasters";
import LoaderSpinner from "../../Components/LoaderSpinner";
import { formatDate1 } from "../../Components/Requests/Requests";
import tokens_icon from "../../assets/RestDash/tokens.svg";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import PaginationComponent from "../../Components/PaginationComponent/PaginationComponent";

const RestaurantDetails = () => {
  const { id } = useParams();
  const [restData, setRestData] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [totalItems, setTotalItems] = useState();
  //   get orders
  const getOrders = async (
    id,
    name,
    status,
    orderMethod,
    orderDate,
    orderType,
    restaurant_id,
    page,
    token
  ) => {
    try {
      const result = await GetRestaurantOrdersDetails(
        id,
        status,
        name,
        orderMethod,
        orderDate,
        orderType,
        restaurant_id,
        page,
        token
      );
      setOrders(result.data.reverse());
      setCurrentPage(result.pagination.page);
      setTotalPages(result.pagination.totalPages);
      setHasNextPage(result.pagination.hasNextPage);
      setHasPreviousPage(result.pagination.hasPreviousPage);
      setTotalItems(result.pagination.totalItems);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getAllRestaurants(id, "", "", "", "");
        setRestData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        notifyError(error.response.data.message);
      }
    })();

    getOrders("", "", "", "", "", "", id, currentPage, token);
  }, [token, id, currentPage]);

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <RestDashNav
            title={`${restData[0]?.name} Restaurant`}
            redirect_path={"/restaurantLists"}
          />
          <div className="container p-0">
            {restData.map((rest) => {
              return (
                <div key={rest?.id} className="ticket-details">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex flex-column">
                      <span
                        style={{
                          color: "#636363",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Restaurant ID
                      </span>
                      <b
                        style={{
                          color: "#415FAC",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        #{rest?.id}
                      </b>
                    </div>
                    <span
                      style={
                        rest?.status === true
                          ? { backgroundColor: "#129D54" }
                          : { backgroundColor: "#FF3434" }
                      }
                      className="rest-active"
                    >
                      {rest?.status === true ? "Active" : "Inactive"}
                    </span>
                  </div>
                  <div className="hr" />
                  <h3>Restaurant Details</h3>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span
                      style={{
                        color: "#636363",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Restaurant Name
                    </span>
                    <b className="fs-5">{rest?.name}</b>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span
                      style={{
                        color: "#636363",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Restaurant Number
                    </span>
                    <b className="fs-5">{rest?.phone}</b>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span
                      style={{
                        color: "#636363",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Address
                    </span>
                    <b className="fs-5">{rest?.address}</b>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span
                      style={{
                        color: "#636363",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Creation Date
                    </span>
                    <b className="fs-5">{formatDate1(rest?.createdAt)}</b>
                  </div>
                  <div className="hr" />
                  <h3>Subscribtion Details</h3>
                  {rest?.RestaurantSubscriptions.map((sub, index) => {
                    return (
                      <div key={index}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <span
                            style={{
                              color: "#636363",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Amount
                          </span>
                          <b className="text-success fs-5">{sub?.amount} JOD</b>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <span
                            style={{
                              color: "#636363",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Subscription End Date
                          </span>
                          <b className="text-danger fs-5">
                            {formatDate1(sub?.endDate)}
                          </b>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <span
                            style={{
                              color: "#636363",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Number Of Tokens
                          </span>
                          <div className="d-flex align-items-center gap-2">
                            <b className="fs-5" style={{ color: "#F4A223" }}>
                              {(rest?.totalTokens / 1000).toFixed(0)}K
                            </b>
                            <img src={tokens_icon} alt="icon" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="hr"></div>
                  <h3>Order List</h3>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span
                      style={{
                        color: "#636363",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Number Of Orders
                    </span>
                    <b className="text-dark fs-5">{rest?.ordersCount}</b>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(226, 226, 226, 0.9333)",
                      borderRadius: "15px",
                      padding: "15px",
                    }}
                  >
                    <Table
                      responsive
                      borderless
                      bgcolor="transparent"
                      style={{
                        textWrap: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ paddingBottom: "25px" }}>
                            Order Number
                          </th>
                          <th style={{ paddingBottom: "25px" }}>
                            Customer Details
                          </th>
                          <th style={{ paddingBottom: "25px" }}>Items</th>
                          <th style={{ paddingBottom: "25px" }}>
                            Total Amount
                          </th>
                          <th style={{ paddingBottom: "25px" }}>
                            Payment Method
                          </th>
                          <th style={{ paddingBottom: "25px" }}>Order Date</th>
                          <th style={{ paddingBottom: "25px" }}>Status</th>
                          <th style={{ paddingBottom: "25px" }}>Order Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.length > 0 ? (
                          orders.map((order) => {
                            return (
                              <tr key={order?.id}>
                                <th
                                  scope="row"
                                  style={{
                                    color: "#415FAC",
                                    textAlign: "center",
                                  }}
                                >
                                  #{order?.id}
                                </th>
                                <td>
                                  <p className="fw-bold">{order?.name}</p>
                                  {/* <p className="fw-bold">{order?.address}</p> */}
                                  <p className="fw-bold">
                                    {order?.customer?.phone}
                                  </p>
                                </td>
                                <td>
                                  <div className="gredients">
                                    <p className="fw-bold">
                                      {order.items.length > 0 ? (
                                        order.items.map((item) => (
                                          <>
                                            <p>
                                              {item?.qty}x {item?.english_name}
                                            </p>
                                            {/* <span
                                              style={{
                                                color: "rgba(0, 0, 0, 0.5)",
                                                cursor: "pointer",
                                              }}
                                            >
                                              ... More
                                            </span> */}
                                          </>
                                        ))
                                      ) : (
                                        <span className="text-danger fw-bold">
                                          No Items
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <span className="total-amount text-success fw-bold">
                                    {order?.total} JOD
                                  </span>
                                </td>
                                <td className="fw-bold">
                                  {order?.paymentMethod}
                                </td>
                                <td className="fw-bold">
                                  {formatDate1(order?.createdAt)}
                                </td>
                                <td>
                                  <span
                                    className="order-status"
                                    style={
                                      order?.status === "completed" ||
                                      order?.status === "received"
                                        ? { backgroundColor: "#129D54" }
                                        : order?.status === "in progress"
                                        ? {
                                            backgroundColor: "#F4A223",
                                          }
                                        : { backgroundColor: "#FF3434" }
                                    }
                                  >
                                    {order?.status}
                                  </span>
                                </td>
                                <td>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                      textAlign: "center",
                                    }}
                                  >
                                    {order?.orderType}
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <p className="empty-users text-danger fw-bold fs-5">
                                No Orders Found
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              );
            })}
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
            />
            <DashBoardFooter title={"Powered by SmartServe"} />
          </div>
        </>
      )}
    </>
  );
};

export default RestaurantDetails;
