import { Col, Row } from "react-bootstrap";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import "./dashBoard.css";
import dollar from "../../assets/RestDash/dollar.png";
import indicator from "../../assets/RestDash/dashBoard/indicator.png";
import charts from "../../assets/RestDash/dashBoard/charts.png";
import stt_icon from "../../assets/RestDash/stt.png";
import rest_icon from "../../assets/RestDash/restaurant.png";
import food_1 from "../../assets/RestDash/menu/menu_1.png";
import person_1 from "../../assets/RestDash/navbar/person.png";

import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import { useEffect, useState } from "react";
import {
  getAllCompanies,
  getAllRestaurants,
  getJoinRequest,
  getRestaurantOrders,
  getTotalSales,
} from "../../utilies/api";
import { formatDate1 } from "../../Components/Requests/Requests";
import { useNavigate } from "react-router-dom";
import LoaderSpinner from "../../Components/LoaderSpinner";

const DashBoard = () => {
  const token = localStorage.getItem("admin_token");
  const navigate = useNavigate();
  const [sales, setSales] = useState(0);
  const [requests, setRequests] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allRestaurants, setAllRestaurants] = useState(0);
  const [activeRestaurants, setActiveRestaurants] = useState(0);
  const [inactiveRestaurants, setInactiveRestaurants] = useState(0);
  const [restaurantsOrders, setRestaurantsOrders] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const getTotalSalesFun = async (token) => {
    try {
      const response = await getTotalSales(token);
      setSales(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getRequests = async () => {
    try {
      const { data } = await getJoinRequest();
      setRequests(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getAllCompaniesFun = async () => {
    try {
      const { data } = await getAllCompanies();
      setAllCompanies(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getAllRestaurantsFun = async () => {
    try {
      const response = await getAllRestaurants("", "", "");
      setAllRestaurants(response?.pagination?.totalRecords);
      const response1 = await getAllRestaurants("", "", "true");

      setActiveRestaurants(response1?.pagination?.totalRecords);
      const response2 = await getAllRestaurants("", "", "false");

      setInactiveRestaurants(response2?.pagination?.totalRecords);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getRestaurantsOrdersFun = async (token) => {
    try {
      const { data } = await getRestaurantOrders(token);
      setRestaurantsOrders(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrMsg(error.response.data.message);
    }
  };
  useEffect(() => {
    getTotalSalesFun(token);
    getRestaurantsOrdersFun(token);
    getAllRestaurantsFun();
    getRequests();
    getAllCompaniesFun();
  }, [token]);
  return (
    <>
      <RestDashNav title={"Dashboard"} subTitle={"SmartServe Dashboard "} />
      {isLoading ? (
        <div className="h-100">
          <LoaderSpinner />
        </div>
      ) : (
        <div className="my-orders dashboard">
          <Row>
            <Col xs={12} md={3} lg={3} xl={2} className="mb-3">
              <div className="total-sales">
                <div className="img-price">
                  <img src={dollar} alt="dollar" />
                </div>
                <h3>{sales} JOD</h3>
                <p>Total sales</p>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} xl={2} className="mb-3">
              <div className="total-revenu">
                <div className="img-revenu">
                  <img src={indicator} alt="indicator" />
                </div>
                <h3>{sales} JOD</h3>
                <p>Revenue</p>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6} xl={3} className="mb-3">
              <div className="charts-sales d-flex">
                <div
                  className="total-sales text-center"
                  style={{ backgroundColor: "transparent", padding: "0" }}
                >
                  <div className="img-price">
                    <img src={dollar} alt="dollar" />
                  </div>
                  <h3>{(sales * 100) / 100}%</h3>
                  <p>sales</p>
                </div>
                <div className="charts-img">
                  <img className="w-100" src={charts} alt="charts" />
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6} xl={3} className="mb-3">
              <div
                className="charts-sales d-flex"
                style={{ alignItems: "center" }}
              >
                <div
                  className="total-sales text-center"
                  style={{ backgroundColor: "transparent", padding: "0" }}
                >
                  <div className="img-price">
                    <img
                      src={rest_icon}
                      alt="dollar"
                      style={{ backgroundColor: "#129D54" }}
                    />
                  </div>
                </div>
                <div className="charts-img">
                  <div style={{ textAlign: "start", marginBottom: "15px" }}>
                    <h3>{allRestaurants}</h3>
                    <p>Total restaurants</p>
                  </div>
                  <div
                    style={{
                      textAlign: "start",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="active-rest">
                      <h4 style={{ color: "#129D54" }}>{activeRestaurants}</h4>
                      <p>Active</p>
                    </div>
                    <div className="inactive-rest">
                      <h4 style={{ color: "#FF3434" }}>
                        {inactiveRestaurants}
                      </h4>
                      <p>In active</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6} xl={2} className="mb-3">
              <div className="total-revenu">
                <div className="img-revenu">
                  <img
                    src={stt_icon}
                    alt="indicator"
                    style={{ backgroundColor: "#1A1F2C" }}
                  />
                </div>
                <h3>{allCompanies.length}</h3>
                <p>Total subscribers</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4} xl={4} className="mb-3">
              <div
                className="fav-items py-3 px-3"
                style={{ borderRadius: "20px", height: "335px" }}
              >
                <div className="fav-heading">
                  <h2>Top Restaurants orders </h2>
                </div>
                {restaurantsOrders && restaurantsOrders.length > 0 ? (
                  restaurantsOrders.map((order) => (
                    <div className="fav-item mb-3" key={order.id}>
                      <div className="fav-item-img d-flex">
                        <img src={food_1} alt="food" />
                        <span className="px-2 text-nowrap">{order?.name}</span>
                      </div>
                      <div
                        style={{ color: "rgba(217, 217, 217, 1)" }}
                        className="fav-item-info d-flex"
                      >
                        <span className="px-1">
                          {(order?.percentage).toFixed(1)}%
                        </span>
                        <span>({order?.count})</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {errMsg}
                  </p>
                )}
              </div>
            </Col>
            <Col xs={12} md={8} lg={8} xl={8}>
              <div
                className="table-container table-responsive mb-3 text-nowrap"
                style={{ borderRadius: "20px", height: "335px" }}
              >
                <div className="fav-heading">
                  <h2>Recent Restaurants Request</h2>
                  <p
                    onClick={() => navigate("/requests")}
                    style={{ backgroundColor: "#129D54" }}
                  >
                    View all
                  </p>
                </div>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Request Date</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Restaurant Name</th>
                      <th scope="col">Address</th>
                    </tr>
                  </thead>
                  {requests && requests.length > 0 ? (
                    requests.map((request) => (
                      <tbody>
                        <tr>
                          <td>{formatDate1(request.createdAt)}</td>
                          <td>{request.email}</td>
                          <td>{request.phone}</td>
                          <td>{request.restaurantName}</td>
                          <td>{request.address}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-danger"
                        colSpan={6}
                      >
                        No Restaurants Requests Found
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </Col>
            <Col xs={12} md={12} lg={12} xl={12}>
              <div
                className="table-container table-responsive mb-4 text-nowrap text-capatialize"
                style={{ borderRadius: "2rem" }}
              >
                <div className="fav-heading">
                  <h2>Subscription Renewal Status</h2>
                  <p
                    style={{ backgroundColor: "#129D54" }}
                    onClick={() => navigate("/sttSubscribers")}
                  >
                    View all
                  </p>
                </div>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Subscriber ID</th>
                      <th scope="col">Subscriber Details</th>
                      <th scope="col">Subscription Amount</th>
                      <th scope="col">Subscription Date </th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Total transcriptions Duration </th>
                    </tr>
                  </thead>
                  {allCompanies.length > 0 ? (
                    allCompanies.slice(0, 5).map((company) => (
                      <tbody key={company?.id}>
                        <tr>
                          <th scope="row">#{company?.id}</th>
                          <td>
                            <div className="rest-info d-flex align-items-center gap-2">
                              <img
                                style={{ width: "3rem", height: "3rem" }}
                                src={person_1}
                                alt="person"
                              />
                              <div className="rest-name">
                                <p>{company?.name}</p>
                                <p>{company?.email}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="total-amount">
                              {company?.Subscription?.Billing || 0} JOD
                            </span>
                          </td>
                          <td>
                            <p>
                              {formatDate1(company?.Subscription?.startDate)}
                            </p>
                          </td>
                          <td>
                            <span
                              style={{ color: "#FF3434" }}
                              className="total-amount"
                            >
                              {formatDate1(company?.Subscription?.endDate)}
                            </span>
                          </td>
                          <td>
                            <p>
                              {company?.Subscription?.numberOfMinutes} Minutes
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-danger"
                        colSpan={6}
                      >
                        No Subscribers Found
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </Col>
            {/* <Col xs={12} md={12} lg={4} xl={4}>
            <div className="fav-items mb-5 py-3" style={{ height: "auto" }}>
              <div className="fav-heading">
                <h2>Users</h2>
                <p style={{ backgroundColor: "#129D54" }}>View all</p>
              </div>
              <div className="fav-item mb-3">
                <div className="fav-item-img">
                  <img src={person_1} alt="food" />
                  <span className="px-2">Adam Ahmed</span>
                </div>
                <div style={{ color: "#000" }} className="fav-item-info">
                  <span>35 hr/week</span>
                </div>
              </div>
              <div className="fav-item mb-3">
                <div className="fav-item-img">
                  <img src={person_2} alt="food" />
                  <span className="px-2">Adel Saied</span>
                </div>
                <div style={{ color: "#000" }} className="fav-item-info">
                  <span>35 hr/week</span>
                </div>
              </div>
              <div className="fav-item mb-3">
                <div className="fav-item-img">
                  <img src={person_3} alt="food" />
                  <span className="px-2">Marwa Mohamed</span>
                </div>
                <div style={{ color: "#000" }} className="fav-item-info">
                  <span>35 hr/week</span>
                </div>
              </div>
              <div className="fav-item mb-3">
                <div className="fav-item-img">
                  <img src={person_4} alt="food" />
                  <span className="px-2">Sara Ali</span>
                </div>
                <div style={{ color: "#000" }} className="fav-item-info">
                  <span>35 hr/week</span>
                </div>
              </div>
              <div className="fav-item mb-3">
                <div className="fav-item-img">
                  <img src={person_5} alt="food" />
                  <span className="px-2">Mohamed Mamdouh</span>
                </div>
                <div style={{ color: "#000" }} className="fav-item-info">
                  <span>35 hr/week</span>
                </div>
              </div>
              <div className="fav-item">
                <div className="fav-item-img">
                  <img src={person_1} alt="food" />
                  <span className="px-2">Adam Ahmed</span>
                </div>
                <div style={{ color: "#000" }} className="fav-item-info">
                  <span>35 hr/week</span>
                </div>
              </div>
            </div>
          </Col> */}
          </Row>
          <DashBoardFooter title="Powered by SmartServe" />
        </div>
      )}
    </>
  );
};

export default DashBoard;
