import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import { useParams } from "react-router-dom";
import TokensChartBar from "./TokensChartBar";
import { useEffect, useState } from "react";
import { getRestTokensAnalytics } from "../../utilies/api";
import LoaderSpinner from "../../Components/LoaderSpinner";

const TokensChart = () => {
  const { id } = useParams();
  const [tokens, setTokens] = useState([]);
  const [filter, setFilter] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getRestChartTokens = async (rest_id, filter) => {
    try {
      const response = await getRestTokensAnalytics(rest_id, filter);
      setTokens(response?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      getRestChartTokens(id, filter);
    }
  }, [id, filter]);
  return (
    <>
      <RestDashNav
        title={"Tokens Overview"}
        redirect_path={"/restaurantLists"}
        // navigate={navigate}
      />
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <div className="my-orders dashboard" style={{ height: "88vh" }}>
          {tokens.length > 0 ? (
            <>
              <div className="d-flex align-items-center justify-content-end">
                <label
                  htmlFor="filter"
                  className="form-label me-3 mb-4 fw-bold fs-3"
                >
                  Filter By:
                </label>
                <select
                  id="filter"
                  onChange={(e) => setFilter(e.target.value)}
                  name="filter"
                  value={filter}
                  className="form-select border-1 fw-bold"
                  style={{ width: "fit-content" }}
                  aria-label="Select time period for tokens"
                >
                  <option value="" disabled>
                    Select Time Period
                  </option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </div>

              <TokensChartBar tokens={tokens} />
            </>
          ) : (
            <b className="text-danger fw-bold fs-3">
              No Charts Available For This Restaurant!
            </b>
          )}
        </div>
      )}
    </>
  );
};

export default TokensChart;
