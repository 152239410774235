import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const AddRestaurantAmountTest = ({ show, handleClose }) => {
  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" show={show}>
      <Modal.Header className="align-items-center justify-content-center border-0 flex-column">
        <h1
          className="mb-3"
          style={{
            fontSize: "32px",
            fontWeight: "bold",
            textDecoration: "capitalize",
          }}
        >
          Add Restaurant Amount
        </h1>
        <h5 style={{ fontSize: "18px", textDecoration: "capitalize" }}>
          Add Amount to{" "}
          {/* <b style={{ color: "#425FAB", textDecoration: "capitalize" }}>
            {allRestaurants[idx].name}
          </b> */}
          <b>Cooper Cooperate</b>
        </h5>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3 fw-bold" controlId="add-amount">
            <Form.Label>Subscription Amount</Form.Label>
            <Form.Control
              type="number"
              min={1}
              placeholder="Subscription Amount"
              // value={restAmount}
              // onChange={(e) => setRestAmount(e.target.value)}
            />
            {/* {userErrMsg ? (
              <span
                className="d-block text-danger text-capitalize my-2 mb-0"
                role="alert"
              >
                {userErrMsg}
              </span>
            ) : null} */}
          </Form.Group>
        </Form>
      </Modal.Body>
      <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
        <Button
          variant="secondary"
          className="w-25 py-2 text-white fs-5 fw-bold rounded-5"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "#415FAC" }}
          className="w-25 py-2 text-white fs-5 fw-bold rounded-5"
          // onClick={() => {
          //   addRestaurantAmount();
          //   setUserLodaing(true);
          // }}
        >
          {/* {userLoading ? <Spinner /> : "Save"} */}
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default AddRestaurantAmountTest;
