import Modal from "react-bootstrap/Modal";

function ClosedTicketModal(props) {
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <h4
          style={{
            fontSize: "2rem",
            fontWeight: "bold",
            textTransform: "capitalize",
            lineHeight: "2.6rem",
            marginBlockEnd: "2rem",
          }}
        >
          Are you sure to want to close this <br /> Ticket?
        </h4>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <button
            onClick={props.onHide}
            style={{
              backgroundColor: "#BBBBBB",
              borderRadius: "5rem",
              border: "none",
              outline: "none",
              padding: "1rem 3.5rem",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "#F15623",
              borderRadius: "5rem",
              border: "none",
              outline: "none",
              padding: "1rem 3rem",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ClosedTicketModal;
