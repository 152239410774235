import { Container } from "react-bootstrap";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";
import bot_logo from "../../assets/RestDash/bot_logo.svg";

const VoiceSubscriberDetails = () => {
  const companyDetails = [
    {
      name: "Company Name",
      value: "Cobber Cooperate",
    },
    {
      name: "Company Email",
      value: "CobberCooperate@gmail.com",
    },
    {
      name: "Subscription Start Date",
      value: "2024-07-01",
    },
    {
      name: "Subscription End Date",
      value: "2024-08-01",
    },
  ];

  return (
    <>
      <RestDashNav
        title={"Subscriber Details"}
        redirect_path={"/real-time-agents"}
      />
      <Container>
        <div className="ticket-details">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex flex-column">
              <span
                style={{
                  color: "#636363",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Subscriber ID
              </span>
              <b
                style={{
                  color: "#415FAC",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                123456
              </b>
            </div>
            <span
              style={{
                backgroundColor: "#129D54",
                color: "white",
                height: "30px",
                lineHeight: "30px",
                width: "95px",
                display: "inline-block",
                textAlign: "center",
                borderRadius: "100px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Active
            </span>
          </div>
          <div className="hr" />
          <h3>Company Details</h3>
          {companyDetails.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center mb-3"
              >
                <span
                  style={{
                    color: "#636363",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {item.name}
                </span>
                <b>{item.value}</b>
              </div>
            );
          })}
          <div className="hr" />
          <h3>Subscription Details</h3>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Amount
            </span>
            <span
              style={{ color: "#129D54", fontSize: "16px", fontWeight: "bold" }}
            >
              12,000 JOD
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              End Date of Subscription
            </span>
            <span
              style={{ color: "#FF3434", fontSize: "16px", fontWeight: "bold" }}
            >
              2025-07-01
            </span>
          </div>
          <div className="hr" />
          <h3>Customization Options</h3>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Company Logo
            </span>
            <img width={44} height={44} src={bot_logo} alt="logo" />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Background Color
            </span>
            <span
              style={{
                border: "1px solid black",
                width: "44px",
                height: "44px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Custom Colors
            </span>
            <div className="d-flex align-items-center gap-2">
              <span
                style={{
                  border: "1px solid black",
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                  display: "inline-block",
                  backgroundColor: "#D9D9D9",
                }}
              ></span>
              <span
                style={{
                  border: "1px solid black",
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                  display: "inline-block",
                  backgroundColor: "rgba(65, 95, 172, 0.14)",
                }}
              ></span>
              <span
                style={{
                  border: "1px solid black",
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                  display: "inline-block",
                  backgroundColor: "#415FAC",
                }}
              ></span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Font
            </span>
            <b style={{ fontWeight: "bold", fontSize: "22px" }}>Poppins</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Bot Avatar
            </span>
            <img src={bot_logo} alt="logo" />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Bot Name
            </span>
            <b style={{ fontWeight: "bold", fontSize: "22px" }}>SmartServe</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Language
            </span>
            <b style={{ fontWeight: "bold", fontSize: "22px" }}>English</b>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span
              style={{ color: "#636363", fontSize: "16px", fontWeight: "500" }}
            >
              Greeting Message
            </span>
            <b style={{ fontWeight: "bold", fontSize: "22px" }}>
              Good morning, What task Can i do for you?
            </b>
          </div>
        </div>
        <div className="mt-5 mb-3">
          <DashBoardFooter title={"Powered by SmartServe"} />
        </div>
      </Container>
    </>
  );
};

export default VoiceSubscriberDetails;
