import React, { useState } from "react";
import "./voiceAddNewSubscriber.css";
import RestDashNav from "../../Components/RestDashNav/RestDashNav";
import {
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import DashBoardFooter from "../../Components/DashBoardFooter/DashBoardFooter";

const VoiceAddNewSubscriber = () => {
  const [inputChecked, setInputChecked] = useState(false);
  return (
    <>
      <RestDashNav
        title={"Add New Subscriber"}
        redirect_path={"/voice-bot-home"}
      />
      <Container className="mt-3">
        <div className="container-section new-subscriber">
          <h3>Subscriber Details</h3>
          <label class="custom-checkbox">
            <input
              onChange={() => setInputChecked(!inputChecked)}
              checked={inputChecked}
              type="checkbox"
            />
            <span class="checkmark"></span>
            New Subscriber
          </label>
          <FormGroup className="form">
            <Row>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Company Name</label>
                <Form.Select
                  name={"sname"}
                  //   onChange={(e) => handleSelect(filter.name, e.target.value)}
                  defaultValue={"Select or add new subscribre"}
                >
                  <option disabled value={"Select or add new subscribre"}>
                    Select or add new subscribre
                  </option>
                  <option value={"upper"}>Upper Crust</option>
                </Form.Select>
              </Col>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Email Address</label>
                <InputGroup>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter Email Address"
                    // value={"uppercrust@smartserve.com"}
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
            </Row>
            {/* account creation */}
            <hr />
            <Row className="mt-4">
              <h3 className="mb-5">Account Creation</h3>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Password</label>
                <InputGroup>
                  <Form.Control
                    type="Password"
                    name="password"
                    placeholder="Password"
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
              <Col lg={6} className="mb-4">
                <label htmlFor="name">Confirm Password</label>
                <InputGroup>
                  <Form.Control
                    type="Password"
                    name="cpassword"
                    placeholder="Password"
                    // onChange={handleChange}
                  />
                </InputGroup>
              </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-end">
              <button className="save">Save</button>
            </div>
          </FormGroup>
        </div>
        <div className="mt-5">
          <DashBoardFooter title={"Powered by SmartServe"} />
        </div>
      </Container>
    </>
  );
};

export default VoiceAddNewSubscriber;
