import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TokensChart = ({ tokens }) => {
  const labels = tokens.map((data) => data.period);
  const totals = tokens.map((data) => data.token);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Tokens Analytics",
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += `${context.raw.toFixed(2)}`;
            return label;
          },
        },
      },
    },
  };

  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: "Tokens",
        data: totals,
        backgroundColor: "#F4A223",
        borderColor: "#F4A223",
        borderWidth: 3,
        barThickness: 25,
        borderRadius: 100,
      },
    ],
  };

  return <Bar data={barChartData} options={options} className="chart" />;
};

export default TokensChart;
